import { storage } from 'src/storage'
import { PrePaywallAnswer, PrePaywallStoredAnswersType, QuizQuestion } from 'src/types/Quiz'
import { PlanPreviewCardType, PlanPreviewCardsType } from 'src/types/QuizPreview'
import FocusCard1 from '../../../public/quiz/quiz-preview-focus-card-1.png'
import FocusCard2 from '../../../public/quiz/quiz-preview-focus-card-2.png'
import FocusCard3 from '../../../public/quiz/quiz-preview-focus-card-3.png'
import FocusCard4 from '../../../public/quiz/quiz-preview-focus-card-4.png'
import FocusCard5 from '../../../public/quiz/quiz-preview-focus-card-5.png'
import FocusCard6 from '../../../public/quiz/quiz-preview-focus-card-6.png'
import FocusCard7 from '../../../public/quiz/quiz-preview-focus-card-7.png'

export const phoneDefaultValue = '___ - ___ - ____'

export const codeDefaultValue = '--- ---'

// validate E.164 compliant number
export const e164Formatted = (phoneNumber: string, countryCode = '+1') =>
  `${countryCode}${phoneNumber.split('-').join('')}`

export const validatePhoneNumber = (phoneNumber: string) => {
  const formattedNumber = e164Formatted(phoneNumber)
  const regex = /^\+[1-9]\d{10,14}$/

  if (
    !regex.test(formattedNumber) &&
    formattedNumber.length &&
    phoneNumber !== phoneDefaultValue &&
    formattedNumber.length >= 10
  )
    return new Error('Not a valid phone number')
  else if (phoneNumber === phoneDefaultValue) return false
  else return true
}

export const resultPageRoutes = Object.values(obe.quizzes).reduce(
  (routeMap, { slug, resultsRoute }) => {
    routeMap[slug] = resultsRoute
    return routeMap
  },
  {} as Record<string, string>,
)

export const muscleGuardCoupons = ['FOUNDFAM30', 'FOUNDFAM45'] as const

export const QUESTION_TYPES = {
  TEXT_ONLY: 'text_only',
  WITH_IMAGE: 'with_image',
  FITNESS_LEVEL: 'fitness_level',
  BIRTHDAY: 'birthday',
  DATE: 'date',
  FREE_TEXT: 'free_text',
  PHONE_NUMBER: 'phone',
  GENDER: 'gender',
  PHONE_VERIFICATION: 'phone_verification',
}

export const DEFAULT_EMPTY_ANSWER = { value: '', choices: [] }

export const getQuestionUrl = (quizSlug: string, questionSlug: string) => {
  const prefix =
    quizSlug === obe.quizzes.quiz_before_paywall.slug ? '/obe-quiz/' : `/quiz/${quizSlug}/`
  return `${prefix}${questionSlug}`
}

export const submitPrePaywallAnswer = (question: QuizQuestion, answer: PrePaywallAnswer) => {
  const answers = JSON.parse(storage.local.getItem(obe.quizzes.quiz_before_paywall.slug) ?? '{}')
  answers[question.id] = {
    value: answer.value,
    choices: answer.choices.map(({ id, value }) => ({ id, value })),
    question: {
      id: question.id,
      slug: question.slug,
    },
  }
  storage.local.setItem(obe.quizzes.quiz_before_paywall.slug, JSON.stringify(answers))
}

export const resetPrePaywallAnswers = () => {
  storage.local.setItem(obe.quizzes.quiz_before_paywall.slug, '{}')
}

export const getPrePaywallAnswers = (): PrePaywallStoredAnswersType => {
  const answers = JSON.parse(storage.local.getItem(obe.quizzes.quiz_before_paywall.slug) ?? '{}')
  return answers
}

// STEP 1: ADD ITEMS FOR EACH FIGMA DESIGN POSSIBLE HERE
export const PLAN_FOCUS_COPY = {
  GAIN_STRENGTH: {
    title: 'Gain Strength + Muscle',
    description:
      'Built on the principle of progressive overload (aka strategically + continually challenging your body to adapt), this type of training is proven to boost your strength, power, and endurance. ',
    bullets: [
      'Build full-body, functional strength',
      'Learn how to lift heavier',
      'Strength and Power classes',
      '3 workouts per week',
      '30 minute sessions',
    ],
    image: FocusCard2,
  },
  BUILD_MUSCLE: {
    title: 'Boost Stamina',
    description:
      'Built on the principle of hypertrophy (aka using high reps to push your muscles to fatigue), this type of training is proven to boost quality lean muscle tissue. ',
    bullets: [
      'Create definition from head to toe',
      'Blend of bodyweight + light resistance work',
      'Sculpt, Barre, and Pilates classes',
      '3 workouts per week',
      '30 minute sessions',
    ],
    image: FocusCard3,
  },
  IMPROVE_OVERALL_FITNESS: {
    title: 'Improve Overall Fitness',
    description:
      'Built on the concept of undulating periodization (aka strategically training different skill sets through varying intensity over time), this is the catch-all of training—leveling up your strength and fitness.',
    bullets: [
      'Improve your health and longevity',
      'Work on strength, mobility, and endurance',
      'Strength, Sculpt, and Power classes',
      '3 workouts per week',
      '30 minute sessions',
    ],
    image: FocusCard4,
  },
  LOSE_WEIGHT: {
    title: 'Lose Weight',
    description:
      'By strategically pairing tempo strength training (to develop lean muscle mass) and cardiovascular conditioning (to strengthen your heart), you’ll become more metabolically flexible, change your body composition, and get strong. ',
    bullets: [
      'Boost your metabolic health',
      'Improve body composition (ratio of lean muscle to fat)',
      'Strength and Power classes',
      '3 classes per week',
      '30 minute workouts',
    ],
    image: FocusCard5,
  },
  SUPPORT_WITH_WEIGHT_LOSS_MEDICATION: {
    title: 'Support with Weight-Loss Medication',
    description:
      'Quickly losing muscle mass (which can result from weight loss medications) puts us at risk of chronic disease, slowed metabolism, and more. A progressive strength-training program leads to the preservation or creation of more lean muscle tissue, protecting you. ',
    bullets: [
      'Build muscle, protect bones, improve body composition',
      'Beginner-friendly, functional strength training ',
      'Full-body Strength and Sculpt classes',
      '3 workouts per week',
      '28-35 minute sessions',
    ],
    image: FocusCard6,
  },
  PRENATAL_STRENGTH_SUPPORT: {
    title: 'Prenatal Strength Support',
    description:
      'Strength training throughout your pregnancy comes with some serious perks! You’ll build overall strength, prepare for the movements of motherhood, maintain + build muscle quality, move easier, and alleviate some of the aches and pains of pregnancy.',
    bullets: [
      'Exercises that are safe throughout pregnancy',
      'Prenatal education on nutrition, sleep, and stress ',
      'Sculpt, Upper Body, and Lower Body Strength classes',
      '4 weekly workouts + 1 Audio talk',
      '10-28 minute sessions',
    ],
    image: FocusCard7,
  },
  POSTNATAL_STRENGTH_SUPPORT: {
    title: 'Postnatal Strength Support',
    description:
      'Broken down into two distinct 6-week phases to mirror the physiological changes throughout your motherhood journey, this type of training helps you safely rebuild strength. ',
    bullets: [
      'Phase 1 is focused on rehab and recovery',
      'Phase 2 is your return to exercise',
      'Pelvic floor, Mobility, Sculpt, Strength, and low-impact Cardio classes',
      '4 workouts per week',
      '10-28 minute sessions',
    ],
    image: FocusCard1,
  },
}

const planCardsCopy: PlanPreviewCardsType = {
  HANDS_ON_SUPPORT: {
    intro: 'Personality Type',
    title: 'Support Seeker',
    subCopy:
      'Not intrinsically wired to form new habits easily, but don’t stress—most people aren’t!',
    description1:
      'A rigid workout plan won’t work for you, because you’re likely juggling many responsibilities or hold an all-or-nothing view on fitness.',
    description2:
      'You’ll benefit from flexibility and an adaptable plan! obé will tell you exactly what to do but gives you the power to shift your personalized plan to work with you, not against you.',
  },
  THRIVE_WITH_SUPPORT: {
    title: 'Accountability Adopter',
    intro: 'Personality Type',
    subCopy: "PSA, you're like most people!",
    description1:
      'We’re often told habit formation is as easy as “putting our minds to it,” but most of us need extra guidance to kick-start new routines.',
    description2:
      'Know that your personalized obé plan is flexible and adaptable, so you can shift classes around as needed.',
    description3:
      'Try out scheduling workouts on your calendar, and checking obé stats for accountability boosts.',
  },
  NEED_GUIDANCE: {
    title: 'Adaptive Achiever',
    intro: 'Personality Type',
    subCopy: "You're good at forming habits - with some help!",
    description1:
      'Initial guidance is key for you to ignite a new routine. Once you’re going, you’re golden!',
    description2:
      'Think of your personalized plan as a “movement prescription,” with flexibility to do your own thing when life comes up.',
    description3:
      'You’ll also thrive with structured obé Programs and Challenges, which teach new skills without the stress of planning workouts.',
  },
  SELF_MOTIVATED: {
    title: 'Motivated Mover',
    intro: 'Personality Type',
    subCopy: "Congrats, you're a unicorn!",
    description1:
      'You have intrinsic motivation and a drive to push yourself, so habit formation comes naturally to you.',
    description2:
      'Setting personal goals and tracking your progress helps you thrive, so take advantage of obé Activity Stats to check your milestones.',
    description3:
      'Follow your class plan to a tee, pick up new skills with Programs, or go for a Challenge—variety will level up your routine!',
  },
}

export const getObePreviewCopy = () => {
  const answers: PrePaywallStoredAnswersType = getPrePaywallAnswers()

  const copy: {
    focusCard: { title: string } | null
    planCard: PlanPreviewCardType | null
  } = {
    focusCard: null,
    planCard: null,
  }

  Object.values(answers).map((answer) => {
    // Generate focus card copy
    if (answer.question.slug === 'fitness-goals') {
      const planFocus = answer.choices
        .map((choice) => choice.value.replaceAll('-', '_'))
        .sort()
        .join('_')
        .toUpperCase()

      switch (planFocus) {
        case 'GAIN_STRENGTH':
          copy.focusCard = PLAN_FOCUS_COPY.GAIN_STRENGTH
          break
        case 'BUILD_MUSCLE':
          copy.focusCard = PLAN_FOCUS_COPY.BUILD_MUSCLE
          break
        case 'IMPROVE_OVERALL_FITNESS':
          copy.focusCard = PLAN_FOCUS_COPY.IMPROVE_OVERALL_FITNESS
          break
        case 'LOSE_WEIGHT':
          copy.focusCard = PLAN_FOCUS_COPY.LOSE_WEIGHT
          break
        case 'SUPPORT_WEIGHT_LOSS_MEDS':
          copy.focusCard = PLAN_FOCUS_COPY.SUPPORT_WITH_WEIGHT_LOSS_MEDICATION
          break
        case 'PRENATAL_SUPPORT':
          copy.focusCard = PLAN_FOCUS_COPY.PRENATAL_STRENGTH_SUPPORT
          break
        case 'POSTNATAL_SUPPORT':
          copy.focusCard = PLAN_FOCUS_COPY.POSTNATAL_STRENGTH_SUPPORT
          break
        default:
          copy.focusCard = PLAN_FOCUS_COPY.GAIN_STRENGTH
      }

      // Generate plan card copy
    } else if (answer.question.slug === 'habit-needs') {
      const planType = answer?.choices[0]?.value?.replaceAll('-', '_').toUpperCase()

      if (planType && planCardsCopy[planType]?.title) {
        copy.planCard = planCardsCopy[planType]
      }
    }
  })

  return copy
}

// slugs of the questions required for the hard-coded preview logic
const REQUIRED_PRE_PAYWALL_SLUGS = [
  'fitness-goals',
  'fitness-journey',
  'class-type',
  'habit-needs',
  'body-literacy',
]

// Used to convert directus slugs to specific tracking names suggested by Product team.
export const slugToTrackingName = {
  'fitness-goals': 'fitnessGoals',
  'fitness-journey': 'profileFitnessLevel',
  'class-type': 'classTypes',
  'habit-needs': 'habit',
  'body-literacy': 'bodyLiteracyImprovements',
} as const

export type QuestionSlugId = keyof typeof slugToTrackingName

export const isPrePaywallQuizFinished = () => {
  const answers = getPrePaywallAnswers()
  const savedSlugs = Object.values(answers).map((answer) => answer?.question?.slug)
  const allAnswered = REQUIRED_PRE_PAYWALL_SLUGS.every((slug) => savedSlugs.includes(slug))
  return allAnswered
}
