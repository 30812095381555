'use client'

import isFunction from 'lodash.isfunction'
import { Redirect } from './Redirect'
import { storage } from 'src/storage'
import { useRouter } from 'src/hooks/useRouter'
import { useUser } from 'src/hooks/useUser'
import { userMustFinishOnboarding } from 'src/utils/userUtils'
import { isPrePaywallQuizFinished } from 'src/providers/Quiz/utils'

const RedirectIfComponent = ({ children, condition, redirect, returnUrl, userStore }) => {
  const router = useRouter()

  if (userStore.isLoadingInitialState) return null

  if (returnUrl) {
    storage.session.setItem('returnUrl', returnUrl)
  }

  if (condition) {
    return <Redirect to={redirect} />
  }

  return isFunction(children) ? children({ router, userStore }) : children
}

export const IsMember = (props) => {
  const userStore = useUser()

  return React.createElement(RedirectIfComponent, {
    ...props,
    condition: userStore.isMember,
    redirect: userMustFinishOnboarding(userStore?.currentUser) ? '/onboarding/welcome' : '/home',
    userStore,
  })
}

export const IsGuest = (props) => {
  const userStore = useUser()

  return React.createElement(RedirectIfComponent, {
    ...props,
    condition: userStore.isGuest,
    redirect: props.returnUrl ? '/login' : '/',
    userStore,
  })
}

export const IsNotGuest = (props) => {
  const userStore = useUser()

  return React.createElement(RedirectIfComponent, {
    ...props,
    condition: !userStore.isGuest,
    redirect: userStore.isMember
      ? props.redirect
        ? props.redirect
        : '/home'
      : props.paywall
        ? '/onboarding/subscription'
        : '/',
    userStore,
  })
}

const GuestOrSubscriber = (props) => {
  const userStore = useUser()

  return React.createElement(RedirectIfComponent, {
    ...props,
    condition: userStore.isGuest || userStore.isMember,
    redirect: userStore.isGuest ? '/login' : '/home',
    userStore,
  })
}

export const RedirectIf = {
  GuestOrSubscriber,
  CantUpdateSubscription: (props) => {
    const user = useUser()

    return (
      <RedirectIfComponent
        {...props}
        condition={user.isGuest || user.isNonbillable || user.isCanceled}
        redirect={user.isGuest ? '/login' : '/'}
        userStore={user}
      />
    )
  },
  CantUseSocialFeatures: (props) => {
    const userStore = useUser()

    return React.createElement(RedirectIfComponent, {
      ...props,
      condition: !userStore.canUseSocialFeatures,
      redirect: userStore.isGuest ? '/login' : userStore.isMember ? '/profile' : '/',
      userStore,
    })
  },
  HasUsername: (props) => {
    const userStore = useUser()

    return React.createElement(RedirectIfComponent, {
      ...props,
      condition: !!userStore?.username,
      redirect:
        (typeof props.redirect === 'function'
          ? props.redirect(userStore?.username)
          : props.redirect) || `/${userStore?.username}`,
      userStore,
    })
  },
  IsGuest,
  IsNotGuest: (props) => {
    const userStore = useUser()

    return React.createElement(RedirectIfComponent, {
      ...props,
      condition: !userStore.isGuest,
      redirect: userStore.isMember
        ? props.redirect
          ? props.redirect
          : '/home'
        : props.paywall
          ? '/onboarding/subscription'
          : '/',
      userStore,
    })
  },
  IsMember: (props) => {
    const userStore = useUser()

    return React.createElement(RedirectIfComponent, {
      ...props,
      condition: userStore.isMember,
      redirect: userMustFinishOnboarding(userStore?.currentUser) ? '/onboarding/welcome' : '/home',
      userStore,
    })
  },
  IsPartnershipFreemiumEligible: (props) => {
    const { freemiumExpired, ...userStore } = useUser()
    const freemiumRedirectRoute = freemiumExpired ? '/membership/choose-plan' : '/home'

    return React.createElement(RedirectIfComponent, {
      ...props,
      condition: userStore.isMember,
      redirect: freemiumRedirectRoute,
      userStore,
    })
  },
  HasPrePaywallQuizIncomplete: (props) => {
    const userStore = useUser()
    const finished = isPrePaywallQuizFinished()

    return React.createElement(RedirectIfComponent, {
      ...props,
      condition: !finished,
      redirect: '/obe-quiz',
      userStore,
    })
  },
  IsAnnual: (props) => {
    const userStore = useUser()

    return React.createElement(RedirectIfComponent, {
      ...props,
      condition: userStore.isAnnual,
      redirect: '/home',
      userStore,
    })
  },
}
